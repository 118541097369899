import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import moment from 'moment'
moment.locale('et')

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    loading: false,
    config: {
      api: 'https://vedu.airok.eu/api/index.php',
      gmapsApi: 'https://maps.googleapis.com/maps/api/geocode/json',
      gmapsKey: 'AIzaSyBfvfxPuC87qj8IAuQSRblGB_wD-Ptkpo0'
    },
    user: {
      data: [],
      datetime: null,
      notifications: null
    },
    synced: false,
    orders: [],
    selectedOrders: []
  },
  mutations: {
    loading (state, payload) {
      state.loading = payload
    },
    login (state, payload) {
      state.user.data = payload
      this.dispatch('getOrders')
      state.loading = true
    },
    logout (state) {
      state.user.data = []
      // state.user.datetime = null
      state.orders = []
    },
    setSync (state, payload) {
      state.synced = payload
    },
    setOrders (state, payload) {
      state.orders = payload
      // Calculate delivery deadlines
      state.orders.forEach(function (o) {
        // Delivery time is not set
        if (moment(o.datetime).format('HH:mm') === '00:00') {
          o.deadline = moment(o.datetime).add({ hours: 23, minutes: 59 })
          o.timed = false
        } else {
          o.deadline = moment(o.datetime)
          o.timed = true
        }
        // Compile contact details
        const contactdetails = []
        let contactphones = 0

        o.CONTACTS.forEach(function (c) {
          contactphones = (c.mobilephone !== null && c.mobilephone !== '') ? contactphones + 1 : contactphones
          contactphones = (c.phone !== null && c.phone !== '') ? contactphones + 1 : contactphones
          contactphones = (c.phone2 !== null && c.phone2 !== '') ? contactphones + 1 : contactphones

          contactdetails.push({
            id: c.id,
            location_id: c.location_id,
            name: c.name,
            email: (c.email !== null && c.email !== '') ? c.email : null,
            mobile: (c.mobilephone !== null && c.mobilephone !== '') ? c.mobilephone : null,
            phone: (c.phone !== null && c.phone !== '') ? c.phone : null,
            phone2: (c.phone2 !== null && c.phone2 !== '') ? c.phone2 : null,
            type: c.occupation_id,
            main: (o.customerlocation_id === c.location_id)
          })
        })
        o.contactdetails = contactdetails
        o.contactphones = (contactphones > 0)
      })
      state.synced = true
      console.log('Fresh orders list:')
      console.log(payload)
      state.loading = false
    },
    updateOrder (state, payload) {
      const index = state.orders.findIndex(obj => obj.documentno === payload.documentno)
      state.orders[index] = payload
      // Calculate order delivery deadline
      // Delivery time is not set
      if (moment(state.orders[index].datetime).format('HH:mm') === '00:00') {
        state.orders[index].deadline = moment(state.orders[index].datetime).add({ hours: 23, minutes: 59 })
        state.orders[index].timed = false
      } else {
        state.orders[index].deadline = moment(state.orders[index].datetime)
        state.orders[index].timed = true
      }
      // Compile contact details
      const contactdetails = []
      let contactphones = 0

      state.orders[index].CONTACTS.forEach(function (c) {
        contactphones = (c.mobilephone !== null && c.mobilephone !== '') ? contactphones + 1 : contactphones
        contactphones = (c.phone !== null && c.phone !== '') ? contactphones + 1 : contactphones
        contactphones = (c.phone2 !== null && c.phone2 !== '') ? contactphones + 1 : contactphones

        contactdetails.push({
          id: c.id,
          location_id: c.location_id,
          name: c.name,
          email: (c.email !== null && c.email !== '') ? c.email : null,
          mobile: (c.mobilephone !== null && c.mobilephone !== '') ? c.mobilephone : null,
          phone: (c.phone !== null && c.phone !== '') ? c.phone : null,
          phone2: (c.phone2 !== null && c.phone2 !== '') ? c.phone2 : null,
          type: c.occupation_id,
          main: (state.orders[index].customerlocation_id === c.location_id)
        })
      })
      state.orders[index].contactdetails = contactdetails
      state.orders[index].contactphones = (contactphones > 0)
    },
    setDate (state, payload) {
      state.user.datetime = payload
    },
    hideOrder (state, payload) {
      this.state.orders = this.state.orders.filter(order => order !== payload)
    },
    toggleSelectedOrders (state, payload) {
      if (payload !== null) {
        if (this.state.selectedOrders.includes(payload)) {
          this.state.selectedOrders = this.state.selectedOrders.filter(order => order !== payload)
        } else {
          this.state.selectedOrders.push(payload)
        }
      } else {
        this.state.selectedOrders = []
      }
    },
    manageFCMToken (state, payload) {
      const _this = this
      axios.get(_this.state.config.api + '?act=push&method=' + payload.method + '&courier=' + _this.state.user.data.resource_id + '&token=' + payload.token)
        .then(function () {
          // console.log('Sent ' + payload.method + ' ' + payload.token + ' to API')
        })
        .catch(function () {
          // console.log('Failed to ' + payload.method + ' ' + payload.token + ' to API')
          _this.state.user.notifications = null
        })
      // Set state to mirror the selection
      switch (payload.method) {
        case 'save':
          state.user.notifications = payload.token
          break
        case 'remove':
          state.user.notifications = null
          break
      }
    }
  },
  actions: {
    getOrders () {
      const _this = this
      _this.commit('loading', true)
      console.log('resource_id', _this.state.user.data.resource_id)
      axios.get(_this.state.config.api + '?act=query&qid=API_ORDERS&param={"resource_id":' + _this.state.user.data.resource_id + ',"docstate_id":""}&token=' + _this.state.user.data.accesstoken)
        .then(function (response) {
          _this.commit('setOrders', response.data.data)
        })
        .catch(function (error) {
          console.log('$store API_ORDERS error', error)
          alert('Tellimuste laadimine ebaõnnestus!')
        })
    }
  },
  modules: {
  }
})
