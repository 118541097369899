<template>
    <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
        <div class="flex flex-row py-10">
            <img src="../assets/img/logo.svg" class="mx-auto h-20"/>
        </div>
        <form @submit="login">
            <div class="flex flex-row">
                <input type="text" name="username" v-model="username"
                       class="w-full py-2 px-4 outline-none bg-transparent text-white placeholder-gray-300 border-b border-white"
                       placeholder="Kasutajanimi"/>
            </div>

            <div class="flex flex-row py-4">
                <input type="password" name="password" v-model="password"
                       class="w-full py-2 px-4 outline-none bg-transparent text-white placeholder-gray-300 border-b border-white"
                       placeholder="Parool"/>
            </div>

            <div class="flex flex-row py-4 justify-center">
                <button class="rounded-lg text-xl bg-white text-red-400 py-4 px-10 hover:bg-orange-600 hover:text-red-600 outline-none">
                    Sisene
                </button>
            </div>

        </form>
    </div>
</template>

<script>
import axios from 'axios'
const sha1 = require('sha1')

export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  created () {
    if (this.$store.state.user.data.accesstoken) {
      this.$router.push('/orders')
    }
  },
  methods: {
    login (e) {
      e.preventDefault()
      const _this = this
      _this.$store.commit('loading', true)
      axios.get(_this.$store.state.config.api + '?act=query&qid=API_ADMLOGIN&param={"USERNAME":"' + _this.username + '","PASS":"' + sha1(_this.password) + '"}')
        .then(function (response) {
          // console.log(response.data)
          if (response.data.data.length !== 0) {
            _this.$analytics.logEvent('login_ok')
            _this.$store.commit('login', response.data.data[0])
            _this.$router.push('/orders')
          } else {
            _this.$analytics.logEvent('login_incorrect')
            _this.$toast.error('Autoriseerimine ebaõnnestus, kontrolli kasutajanime ja parooli')
            _this.$store.commit('loading', false)
          }
        })
        .catch(function (error) {
          console.log(error)
          _this.$analytics.logEvent('login_error')
          _this.$toast.warning('Autoriseerimine ebaõnnestus, midagi läks valesti')
          _this.$store.commit('loading', false)
        })
    }
  }
}
</script>
