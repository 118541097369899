<template>
  <nav v-if="$store.state.user.data !== []" class="bg-gray-800 p-2 mb-10 fixed w-auto w-full">
    <div class="container mx-auto max-w-lg px-1">
      <div class="flex flex-row">

            <span class="flex-1 text-lg text-gray-400 tracking-tight text-left">
                <router-link to="/orders" class="pr-4 hover:text-white" title="Tellimuste nimekiri">
                    <i class="fas fa-list text-3xl"></i>
                </router-link>

                <router-link to="/map" class="pr-4 pl-4 hover:text-white" title="Kaardivaade">
                    <i class="fas fa-map text-3xl"></i>
                </router-link>

                <router-link to="/loading" class="pr-4 pl-4 hover:text-white" title="Kaubalaadimine">
                    <i class="fas fa-truck-loading text-3xl"></i>
                </router-link>

              <router-link to="/settings"
                           class="font-thin pl-4 text-gray-400 hover:text-white tracking-tight text-right"
                           :title="$store.state.user.data.name">
                  <i class="fas fa-user-cog text-3xl"></i>
                </router-link>
            </span>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      //
    }
  },
  created: function () {
    //
  }
}
</script>
