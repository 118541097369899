<template>
    <div class="pt-12 bg-white height-f" style="position: relative;">
      <div id="map" ref="map"></div>
      <div class="map-legend">
        <!-- All orders -->
        <div class="group border-r py-1 px-2 mr-2 inline-block">
          <img src="@/assets/img/icons/icon_0.png" class="inline-block"> <span class="text-xl">Tellimus</span>
        </div>
        <!-- Selected orders -->
        <div class="group border-r py-1 px-2 inline-block">
          <img src="@/assets/img/icons/icon_1.png" class="inline-block"> <span class="text-xl">Laadimisnimekirjas ({{ $store.state.selectedOrders.length }})</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      map: null,
      geocoder: null,
      locations: [],
      cluster: null,
      mapOptions: {
        zoom: 8,
        gestureHandling: 'greedy',
        clickableIcons: false,
        center: new window.google.maps.LatLng(58.809586, 25.440259),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      }
    }
  },
  mounted () {
    this.initializeMap()
  },
  methods: {
    initializeMap () {
      this.geocoder = new window.google.maps.Geocoder()
      this.map = new window.google.maps.Map(document.getElementById('map'), this.mapOptions)
      this.loadMarkers()
    },
    loadMarkers () {
      const _this = this
      _this.$store.state.orders.forEach(function (order, index) {
        if (order.LOCATION_DATA !== undefined) {
          if (order.LOCATION_DATA.latlng_exist) {
            // console.log('OK - ' + order.LOCATION_DATA.results[0].geometry.location.lat + ', ' + order.LOCATION_DATA.results[0].geometry.location.lng)
            // Create marker
            const coordinates = new window.google.maps.LatLng(order.LOCATION_DATA.lat, order.LOCATION_DATA.lng)

            // Set marker color based on if order is in loading plan
            let markerIcon = require('../assets/img/icons/icon_0.png')
            if (_this.$store.state.selectedOrders.includes(order.documentno)) {
              markerIcon = require('../assets/img/icons/icon_1.png')
            }

            _this.locations[index] = new window.google.maps.Marker({
              position: coordinates,
              title: 'Tellimus #' + order.documentno,
              icon: {
                url: markerIcon
              },
              map: _this.map
            })

            // Add event listeners to marker
            window.google.maps.event.addListener(_this.locations[index], 'click', function () {
              _this.$swal.fire({
                title: 'Tellimus ' + order.documentno,
                html: order.LOCATION_DATA.address + '<p></p><b>' + order.customername + '</b>, tarneaeg ' + _this.moment(order.datetime).format('DD. MMMM'),
                showCancelButton: true,
                confirmButtonColor: '#e55a03',
                cancelButtonColor: '#ccc',
                confirmButtonText: 'Vaata tellimust',
                cancelButtonText: 'Tagasi'
              }).then((result) => {
                if (result.isConfirmed) {
                  _this.$router.push('/order/' + order.documentno)
                }
              })
            })
          } else {
            // console.log(order.id + ' - No geometry data')
          }
        } else {
          // console.log(order.id + ' - No location data')
        }
      })
      /*
      this.cluster = new window.MarkerClusterer(this.map, this.locations,
        {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        }
      )
      */
    }
  }
}
</script>
