<template>
  <div>
    <Navbar v-if="!$route.meta.hideNavigation" style="z-index: 999"></Navbar>
    <div v-if="$store.state.loading" class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-8  border-white h-20 w-20 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">Palun oota</h2>
      <p class="w-1/3 text-center text-white">Toimingut teostatakse, see võib võtta mõne hetke...</p>
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import moment from 'moment'

export default {
  name: 'app',
  components: {
    Navbar
  },
  created () {
    // Set user filter date to current date on page load
    this.$store.commit('setDate', moment(new Date()).format('YYYY-MM-DD'))
    // Try to relogin user
    if (this.$store.state.user.data.accesstoken) {
      // this.$store.dispatch('getOrders')
      // console.log('User had access token, trying to get orders')
    }
    // Receive cloud notifications
    this.$messaging.onMessage((payload) => {
      // console.log('Message received. ', payload)
      this.$store.dispatch('getOrders')
      this.$toast.info(payload.data.body, { timeout: 3000 })
    })
  }
}
</script>
