<template>
  <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
    <h3 class="text-3xl font-thin text-white">
      Seaded
    </h3>

    <!-- Push notifications -->
    <div class="block text-lg text-white">Teavitused</div>

    <div class="flex flex-row mt-2">
      <select class="block w-full py-6 px-4 rounded-lg bg-white" v-model="notifications" @change="setNotifications($event)">
        <option value="on">Teavitused lubatud</option>
        <option value="off">Teavitused keelatud</option>
      </select>
    </div>

    <!-- Log out -->
    <div class="flex flex-row mt-4">
      <router-link to="/logout" class="block w-full bg-white py-2 px-4 rounded-lg font-thin text-center text-2xl text-red-600">
        Logi välja
      </router-link>
    </div>

    <!-- App version -->
    <div class="flex flex-row mt-4 text-sm text-gray-100 font-thin">
      Rakenduse versioon: 04.11.2021-1530
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      notifications: 'off'
    }
  },
  mounted () {
    if (this.$store.state.user.notifications) {
      // console.log('Notifications are enabled, user key: ' + this.$store.state.user.notifications)
      this.notifications = 'on'
    }
  },
  methods: {
    setNotifications (e) {
      const _this = this
      switch (e.target.value) {
        // Notifications enabled by user
        case 'on':
          this.$analytics.logEvent('messaging_on')
          this.$messaging.getToken()
            .then(function (token) {
              _this.notifications = 'on'
              _this.$toast.success('Teavitused sisselülitatud!')
              _this.$store.commit('manageFCMToken', { token: token, method: 'save' })
            })
            .catch(function (e) {
              console.log(e)
              this.$analytics.logEvent('messaging_error')
              _this.$toast.warning('Teavituste aktiveerimine ebaõnnestus!')
              _this.notifications = 'off'
            })
          break
        // Notifications disabled by user
        case 'off':
          this.$analytics.logEvent('messaging_off')
          _this.notifications = 'off'
          _this.$toast.success('Teavitused väljalülitatud!')
          _this.$store.commit('manageFCMToken', { token: _this.$store.state.user.notifications, method: 'remove' })
          break
      }
    }
  }
}
</script>
