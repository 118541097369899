<template>
  <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
    <div class="flex flex-row">
      <div class="flex-1">
        <h3 class="text-2xl font-thin text-white cursor-pointer" @click="sync()">Tellimused <span class="opacity-50">({{ orders.length }})</span> <i
          v-if="!$store.state.synced" class="fas fa-sync text-xl" title="Värskenda tellimuste infot"></i></h3>
      </div>
      <!-- Datepicker -->
      <div class="flex-1 text-right text-white text-xl font-thin">
        <i @click="changeDate('previous')" title="Eelmine päev" class="fas fa-chevron-left pr-2 pt-2 cursor-pointer"></i>
        {{ filter }}
        <i @click="changeDate('next')" title="Järgmine päev" class="fas fa-chevron-right pr-2 cursor-pointer"></i>
      </div>
    </div>

    <div v-if="!orders" class="text-white font-thin">Palun oota...</div>

    <div v-if="orders.length === 0" class="bg-green-500 text-white px-4 py-4 mt-4 rounded-lg">
      <i class="fas fa-check float-right mt-1 text-white"></i>
      Selleks kuupäevaks täitmata tellimusi ei ole!
    </div>

    <div v-if="orders.length > 0">
      <div v-for="order in orders" v-bind:key="order.documentno"
           class="mb-4">
        <!-- Order header -->
        <router-link :to="'/order/' + order.documentno"
                     class="flex flex-row block text-gray-700 hover:bg-gray-100 hover:text-orange-600 bg-white rounded-t mt-2">
          <div class="w-5/6 py-3 px-4">
            <!-- Address -->
            <span class="block text-2xl">
              {{ order.LOCATION_DATA.address || '-' }}
            </span>
            <!-- Customer name -->
            <span class="block text-xl text-gray-600 uppercase">
                {{ order.customername }}
            </span>
          </div>
          <div class="w-1/6 py-5 p-3 text-center">
            <i class="fas fa-folder-open text-2xl"></i><br>Ava
          </div>
        </router-link>

        <!-- Order rows -->
        <div class="bg-gray-200 py-1 px-4 overflow-hidden"
             v-bind:class="{'h-auto': expandedOrder === order.documentno,  'h-20': expandedOrder !== order.documentno && order.ROWS.length >= 3}"
             @click="expandedOrder === order.documentno ? expandedOrder = null : expandedOrder = order.documentno">
          <div class="flex flex-row">
            <div class="w-5/6 text-xl">Tellimus {{ order.documentno }}</div>
            <div class="w-1/6 text-right" v-if="order.ROWS.length >= 3">
              <button class="fas fa-chevron-down text-xl text-gray-500" v-if="expandedOrder !== order.documentno"></button>
              <button class="fas fa-chevron-up text-xl text-gray-500" v-if="expandedOrder === order.documentno"></button>
            </div>
          </div>
          <ul class="text-sm" :id="order.documentno">
            <li v-for="row in order.ROWS" v-bind:key="row.id">
              <span v-if="row.service !== '+' && row.searchcode !== 'KM'" class="text-base block py-1 border-b">
                  {{ row.searchcode }} {{ row.text }} - <span class="lowercase">{{ row.quantity }} {{ row.measurementunit_id }}</span>
              </span>
            </li>
          </ul>
        </div>

        <!-- Delivery instructions -->
        <div v-if="order.text2 || moment(new Date()).unix() < moment(order.deadline).unix() && order.timed === true" class="bg-yellow-200 text-gray-800 px-4 py-4">
          <div v-if="moment(new Date()).unix() < moment(order.deadline).unix() && order.timed === true">
            <b>Tarneaeg: </b> {{ moment(order.deadline).format('DD. MMMM HH:mm') }}
          </div>
          {{ order.text2 }}
        </div>

        <!-- Debug for user "pilvep" user_id 291 -->
        <!--
        <div v-if="$store.state.user.data.user_id === 291" class="bg-black text-white px-4 py-4 md:py-2">
          order.datetime={{ order.datetime }}<br>
          order.deadline readable={{ moment(order.deadline).format('DD. MMMM HH:mm') }}<br>
          order.unique_id={{ order.unique_id }}<br>
          order.timed={{ order.timed }}
        </div>
        -->

        <!-- Delivery time passed -->
        <div v-if="moment(new Date()).unix() > moment(order.deadline).unix()"
             class="bg-red-600 text-white px-4 py-4">
          <i class="fas fa-exclamation-triangle float-right mt-1"></i>
          <span class="font-semibold">Tarne on hilinenud!</span><br> Tarneaeg oli
          {{ moment(order.deadline).format('DD. MMMM') }}
          <span v-if="order.timed === true">{{ moment(order.deadline).format('HH:mm') }}</span>
        </div>

        <!-- Customer contacts -->
        <div class="px-4 py-2 bg-gray-100" v-if="expandedPhones === order.documentno">
          <ContactPhones :contacts="order.contactdetails" :phones="order.contactphones"/>
        </div>

        <!-- Quick actions menu -->
        <div class="flex flex-row">
          <!-- Navigate -->
          <a
            :href="'https://www.google.com/maps/search/?api=1&query=' + order.LOCATION_DATA.address"
            target="_blank"
            class="block cursor-pointer bg-blue-500 hover:bg-blue-600 text-white flex-1 text-center py-4 rounded-b-lg"
            title="Navigeeri sihtkohta">
            <i class="fas fa-directions text-2xl"></i>
          </a>

          <!-- Call button -->
          <div
            v-if="order.contactphones"
            @click="expandedPhones === order.documentno ? expandedPhones = null : expandedPhones = order.documentno"
            class="block cursor-pointer bg-green-500 hover:bg-green-600 text-white flex-1 text-center py-4 rounded-b-lg"
            title="Helista kliendile">
            <i class="fas fa-phone-alt text-2xl"></i>
          </div>

          <!-- Select for loading -->
          <div @click="toggleSelected(order.documentno)"
               title="Lisa või eemalda veo laadimisnimekirjast"
               class="block cursor-pointer bg-gray-600 text-xs text-white flex-1 text-center py-4 rounded-b-lg">
            <i v-if="!$store.state.selectedOrders.includes(order.documentno)"
               class="fas fa-check text-2xl"></i>
            <i v-if="$store.state.selectedOrders.includes(order.documentno)"
               class="fas fa-truck text-2xl"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
import ContactPhones from '../components/ContactPhones'

moment.locale('et')

export default {
  mixins: [Vue2Filters.mixin],
  name: 'Orders',
  components: { ContactPhones },
  data () {
    return {
      orders: [],
      filter: null,
      expandedOrder: null,
      expandedPhones: null,
      expandedContacts: null
    }
  },
  computed: {
    count () {
      return this.$store.state.orders.length
    }
  },
  watch: {
    count (newCount, oldCount) {
      this.getOrders()
    }
  },
  mounted () {
    this.$store.commit('loading', true)
    this.getOrders()
  },
  created () {
    this.$store.dispatch('getOrders')
  },
  methods: {
    getOrders () {
      // console.log(this.$store.state.user.datetime)
      if (this.$store.state.user.datetime === moment(new Date()).format('YYYY-MM-DD')) {
        // Datetime = today -> show all orders up to today (incl.)
        this.filter = 'TÄNA'
        this.orders = this.$store.state.orders.filter(order => moment(order.datetime).unix() < moment().endOf('day').unix())
        // Sort orders by descending delivery time
        this.orders = this.orders.sort((a, b) => (moment(a.deadline).unix() > moment(b.deadline).unix()) ? 1 : ((moment(b.deadline).unix() > moment(a.deadline).unix()) ? -1 : 0))
      } else {
        // Filter orders by selected date
        this.filter = moment(this.$store.state.user.datetime).format('DD. MMMM')
        this.orders = this.$store.state.orders.filter(order => moment(order.datetime).format('YYYY-MM-DD') === this.$store.state.user.datetime)
        // Sort orders by descending delivery time
        this.orders = this.orders.sort((a, b) => (moment(a.deadline).unix() > moment(b.deadline).unix()) ? 1 : ((moment(b.deadline).unix() > moment(a.deadline).unix()) ? -1 : 0))
      }

      this.$store.commit('loading', false)
    },
    changeDate (method) {
      this.$store.commit('loading', true)
      this.$analytics.logEvent('orders_filter')
      let newDate
      switch (method) {
        case 'previous':
          newDate = moment(this.$store.state.user.datetime).add(-1, 'd').format('YYYY-MM-DD')
          break
        case 'next':
          newDate = moment(this.$store.state.user.datetime).add(1, 'd').format('YYYY-MM-DD')
          break
      }

      this.$store.commit('setDate', newDate)
      this.getOrders()
    },
    toggleSelected (order) {
      this.$store.commit('toggleSelectedOrders', order)
      this.$toast.info('Veo laadimisplaani uuendati!')
    },
    sync () {
      this.$store.commit('loading', true)
      this.$store.dispatch('getOrders')
    }
  }
}
</script>
