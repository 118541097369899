<template>
  <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
    <h3 class="text-3xl font-thin text-white">
      Tellimus {{ this.$route.params.order_id }}
    </h3>
    <div v-if="row">
      <!-- Modify title-->
      <div class="block text-lg text-white">{{ row.text }}</div>

      <!-- Modify buttons -->
      <div class="flex mt-4">
        <button @click="quantity > 1 ? quantity = quantity - 1 : quantity = 1"
                class="flex-1 rounded-l text-6xl font-thin text-gray-700 text-center bg-white px-4 py-2">
          -
        </button>
        <div class="flex-1 font-thin text-gray-700 text-center bg-white px-4 py-2 ml-px mr-px">
                        <span class="block">
                            Kogus
                        </span>
          <span class="block text-5xl">
                            {{ quantity }}
                        </span>
          <span class="block lowercase">
                            {{ row.measurementunit_id }}
                        </span>
        </div>
        <button @click="quantity = quantity + 1"
                class="flex-1 rounded-r text-6xl font-thin text-gray-700 text-center bg-white px-4 py-2">
          +
        </button>
      </div>

      <!-- Confirm or cancel modify -->
      <div class="flex flex-row mt-4 mb-8">
        <div class="w-1/3 pr-1">
          <router-link :to="'/order/' + this.$route.params.order_id"
                       class="block w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-3xl text-red-600">
            Tühista
          </router-link>
        </div>

        <div class="w-2/3 pl-1">
          <button @click="updateRow()"
                  class="block w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-3xl text-green-600">
            Salvesta
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      order: null,
      quantity: 0,
      quantityBefore: 0,
      row: null,
      ODOC: null
    }
  },
  mounted () {
    this.openRow()
  },
  methods: {
    openRow () {
      this.$store.commit('loading', true)
      this.order = this.$store.state.orders.find(item => item.documentno === this.$route.params.order_id)
      this.row = this.order.ROWS.find(item => item.rowno === parseInt(this.$route.params.row_no))
      console.log(this.row)
      this.quantity = this.row.quantity
      this.quantityBefore = this.row.quantity
      this.$store.commit('loading', false)
    },
    updateRow () {
      const _this = this
      _this.$analytics.logEvent('order_modify_start')
      _this.$store.commit('loading', true)
      axios.get(_this.$store.state.config.api + '?act=ODOC_ITEMSETQTY&docuid=' + _this.order.unique_id + '&rowno=' + parseInt(this.$route.params.row_no) + '&quantity=' + this.quantity + '&accesstoken=' + _this.$store.state.user.token)
        .then(function (response) {
          console.log('ODOC_ITEMSETQTY:')
          console.log(response)
          // Add event for quantity change
          const eventComment = _this.$store.state.user.data.name + ' muutis rea ' + _this.$route.params.row_no + ' [' + _this.row.text + '] kogust ' + _this.quantityBefore + ' ' + _this.row.measurementunit_id + ' -> ' + _this.quantity + ' ' + _this.row.measurementunit_id
          axios.get(_this.$store.state.config.api + '?act=ODOC_ADDEVENT&docuid=' + _this.order.unique_id + '&de_act=MODIFY&de_comment=' + eventComment + '&accesstoken=' + _this.$store.state.user.data.accesstoken)
            .then(function (response) {
              _this.$analytics.logEvent('order_modify_complete')
              _this.$router.push('/order/' + _this.$route.params.order_id)
            })
            .catch(function (error) {
              console.log('ODOC_ADDEVENT error')
              console.log(error)
              this.$toast.error('Muudatusele sündmuse lisamine ebaõnnestus (ODOC_ADDEVENT)...', { timeout: 2000 })
            })
        })
        .catch(function (error) {
          console.log(error)
          console.log('ODOC_ITEMSETQTY error')
          this.$toast.error('Muudatuse salvestamine ebaõnnestus (ODOC_ITEMSETQTY)...', { timeout: 2000 })
        })
    }
  }
}
</script>
