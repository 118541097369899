<template>
  <div>
    <div v-if="phones === true" class="overflow-hidden" v-bind:class="{'h-auto': expanded === true,  'h-20': expanded === false && contacts.length > 1}">
      <div class="float-right" v-if="contacts.length > 1">
        <button class="fas fa-chevron-down text-xl text-gray-500 pb-4 pl-4" v-if="expanded === false" @click="expanded = true"></button>
        <button class="fas fa-chevron-up text-xl text-gray-500 pb-4 pl-4" v-else @click="expanded = false"></button>
      </div>
      <div v-for="contact in orderBy(contacts, 'main', -1)" :key="contact.id">
        <div v-if="contact.mobile !== null || contact.phone !== null || contact.phone2 !== null">
          <span class="text-xl">{{ contact.name || '(Nimi puudub)' }}</span>
          <br>
          <!-- mobile -->
          <a v-if="contact.mobile !== null" :href="'tel:' + contact.mobile" class="inline-block bg-green-500 hover:bg-green-600 text-white mr-1 mb-1 px-4 py-2 rounded-lg">
            <i class="fas fa-phone-alt"></i> {{ contact.mobile }}
          </a>
          <!-- phone -->
          <a v-if="contact.phone !== null" :href="'tel:' + contact.phone" class="inline-block bg-green-500 hover:bg-green-600 text-white mr-1 mb-1 px-4 py-2 rounded-lg">
            <i class="fas fa-phone-alt"></i> {{ contact.phone }}
          </a>
          <!-- phone2 -->
          <a v-if="contact.phone2 !== null" :href="'tel:' + contact.phone2" class="inline-block bg-green-500 hover:bg-green-600 text-white mr-1 mb-1 px-4 py-2 rounded-lg">
            <i class="fas fa-phone-alt"></i> {{ contact.phone2 }}
          </a>
          <!-- Type and priority -->
          <span class="inline-block bg-blue-400 text-white mr-1 mb-1 px-4 py-2 rounded-lg">
                  <span v-if="contact.main"><i class="fas fa-star pr-2"></i> </span>
                  <span v-if="contact.type === 'VV'">Vastuvõtja</span>
                  <span v-else-if="contact.type === 'BK'">Raamatupidaja</span>
                </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
export default {
  props: ['contacts', 'phones'],
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      expanded: false
    }
  }
}
</script>
