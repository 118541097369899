import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Settings from '../views/Settings.vue'
import Orders from '../views/Orders.vue'
import Map from '../views/Map.vue'
import Order from '../views/Order.vue'
import Modify from '../views/Modify.vue'
import Events from '../views/Events.vue'
import Loading from '../views/Loading'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      hideNavigation: true,
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order/:order_id',
    name: 'Order',
    component: Order,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/modify/:order_id/:row_no',
    name: 'Modify',
    component: Modify,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events/:order_id',
    name: 'Events',
    component: Events,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user.data.accesstoken) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
