import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// Toast notifications
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// Array filters
import Vue2Filters from 'vue2-filters'

// Sweetalert for notifications and checks
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// Moment for time manipulation
import moment from 'moment'

// General styles
import './assets/styles/style.css'

// Firebase dependencies
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCGxyfBYiPQXISNBT_7MLZrh0dILuBrpxA',
  authDomain: 'airok-kuller.firebaseapp.com',
  projectId: 'airok-kuller',
  storageBucket: 'airok-kuller.appspot.com',
  messagingSenderId: '348729099137',
  appId: '1:348729099137:web:c231f8b20f746ce0a21e80',
  measurementId: 'G-CGSY62134P'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.messaging()

Vue.prototype.$analytics = firebase.analytics()
Vue.prototype.$messaging = firebase.messaging()

Vue.prototype.moment = moment

const toastOptions = {
  timeout: 3000
}

Vue.use(Toast, toastOptions)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
