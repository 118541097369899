<template>
    <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
        <h3 class="text-3xl font-thin text-white">
            Tellimus {{ this.$route.params.order_id }}
        </h3>
        <div v-if="!order" class="text-white font-thin">Palun oota...</div>
        <div v-if="order">
            <div class="block text-lg text-white">Lisa sündmus</div>

            <!-- Shortcut -->
            <div class="flex flex-row mt-2">
                <select class="block w-full py-6 px-4 rounded-lg bg-white" name="payment" @change="setEvent($event)">
                    <option disabled selected>Sündmuse kiirvalik</option>
                    <option v-for="reason in shortcuts" v-bind:key="reason.id" :value=" reason.value ">{{ reason.value }}</option>
                </select>
            </div>

            <!-- Event content -->
            <div class="flex mt-4">
                <textarea class="bg-white py-6 px-4 rounded-lg w-full placeholder-gray-500" name="notes" v-model="event" placeholder="Sisesta tellimusega seonduv sündmus..."></textarea>
            </div>

            <!-- Confirm or cancel event -->
            <div class="flex flex-row mt-4 mb-8">
                <div class="w-1/3 pr-1">
                    <router-link :to="'/order/' + order.documentno" class="block w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-3xl text-red-600">
                        Tühista
                    </router-link>
                </div>

                <div class="w-2/3 pl-1">
                    <button @click="addEvent()" class="block w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-3xl text-green-600">
                        Lisa sündmus
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      order: null,
      event: null,
      shortcuts: [
        { id: 1, value: 'Klienti ei saadud kätte' },
        { id: 2, value: 'Klient soovis kohaletoimetamise aega muuta' },
        { id: 3, value: 'Kohaletoimetamine ebaõnnestus muul põhjusel' }
      ]
    }
  },
  mounted () {
    this.openOrder()
  },
  methods: {
    openOrder () {
      this.$store.commit('loading', true)
      this.order = this.$store.state.orders.find(item => item.documentno === this.$route.params.order_id)
      this.$store.commit('loading', false)
    },
    setEvent (e) {
      this.event = e.target.value
    },
    addEvent () {
      const _this = this
      _this.$store.commit('loading', true)
      _this.$analytics.logEvent('order_event_start')
      axios.get(_this.$store.state.config.api + '?act=ODOC_ADDEVENT&docuid=' + _this.order.unique_id + '&de_act=SASS&de_comment=' + _this.$store.state.user.data.name + ': ' + _this.event + '&accesstoken=' + _this.$store.state.user.data.accesstoken)
        .then(function (response) {
          let timerInterval
          _this.$swal.fire({
            title: 'Sündmus salvestatud!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              timerInterval = setInterval(() => {}, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            _this.$analytics.logEvent('order_event_complete')
            _this.$router.push('/order/' + _this.$route.params.order_id)
          })
        })
        .catch(function (error) {
          _this.$store.commit('loading', false)
          console.log('ODOC_ADDEVENT error')
          console.log(error)
          _this.$toast.error('Sündmuse lisamine ebaõnnestus (ODOC_ADDEVENT)...', { timeout: 5000 })
        })
    }
  }
}
</script>
