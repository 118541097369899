<template>
    <div>
        Ühendus katkestatud...
    </div>
</template>

<script>
export default {
  mounted () {
    this.$analytics.logEvent('logout')
    this.$store.commit('logout')
    this.$router.replace({ name: 'Login' })
  }
}
</script>
