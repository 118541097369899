<template>
    <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
        <h3 class="text-3xl font-thin text-white">Veo laadimisplaan</h3>
        <div v-if="items">
            <div class="text-white font-thin">Laadimiseks on valitud {{ this.$store.state.selectedOrders.length }} tellimust</div>
            <div v-if="this.$store.state.selectedOrders.length > 0">
                <!-- Order rows header -->
                <div class="bg-white py-1 px-4 pr-8 mt-4 rounded-t-lg w-full">&nbsp;</div>

                <!-- Order rows -->
                <table class="table-auto w-full bg-white">
                    <thead>
                    <tr>
                        <th class="px-2 pb-2 font-thin">Kogus</th>
                        <th class="px-2 pb-2 font-thin text-left">Artikkel</th>
                        <th class="px-2 pb-2 font-thin text-left">Nimetus</th>
                        <th class="px-2 pb-2 font-thin text-left">Tellimusi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-t" v-for="item in items" v-bind:key="item.searchcode">
                        <td class="px-2 py-2 lowercase text-center">
                            {{ item.quantity }} {{ item.measurementunit_id }}
                        </td>
                        <td class="px-2 py-2">
                            {{ item.searchcode }}
                        </td>
                        <td class="px-2 py-2">
                            {{ item.text }}
                        </td>
                      <td class="px-2 py-2 text-center">
                        {{ item.orders }}
                      </td>
                    </tr>
                    </tbody>
                </table>

                <!-- Order rows footer -->
                <div class="bg-white pb-2 pt-2 px-4 text-right rounded-b-lg w-full">
                    &nbsp;
                </div>

                <!-- Clear loading plan -->
                <div class="flex flex-row mt-4 mb-2">
                    <div class="w-full">
                        <button @click="toggleSelected(null)" class="block w-full bg-white py-1 px-4 rounded-lg font-thin text-center text-xl text-red-600">
                            Tühista laadimisplaan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      items: []
    }
  },
  mounted () {
    this.parseOrders()
  },
  methods: {
    parseOrders () {
      const _this = this
      _this.$store.commit('loading', true)
      // Loop over selected orders
      _this.$store.state.selectedOrders.forEach(function (o) {
        // Pick order from all orders
        const order = _this.$store.state.orders.find(item => item.documentno === o)
        // If order exists in data, work with it, otherwise- ignore, maybe local cache and fresh data are out of sync
        if (order) {
          // Loop over order rows
          order.ROWS.forEach(function (row) {
            // Dont act on delivery and tax rows
            if (row.service !== '+' && row.searchcode !== 'KM') {
              // Check if this item has already been added to the list to update it or add it to the list
              if (_this.items.some(item => item.searchcode === row.searchcode)) {
                const index = _this.items.findIndex(item => item.searchcode === row.searchcode)
                _this.items[index].quantity = _this.items[index].quantity + parseInt(row.quantity)
                _this.items[index].orders = _this.items[index].orders + 1
              } else {
                const newItem = []
                newItem.searchcode = row.searchcode
                newItem.text = row.text
                newItem.quantity = row.quantity
                newItem.measurementunit_id = row.measurementunit_id
                newItem.orders = 1
                _this.items.push(newItem)
              }
            }
          })
        }
      })
      _this.$store.commit('loading', false)
    },
    toggleSelected (order) {
      this.$store.commit('toggleSelectedOrders', order)
      this.parseOrders()
      this.$toast.info('Veose koondvaadet uuendati!')
    }
  }
}
</script>
