<template>
  <div class="container pt-16 mx-auto max-w-lg px-1 pb-4">
    <div v-if="!order" class="text-white font-thin">Palun oota...</div>
    <div v-if="order">
      <!-- Order nr -->
      <h3 class="text-2xl font-thin text-white">
        Tellimus <b>{{ order.documentno }}</b>
      </h3>

      <!-- Address -->
      <div class="flex flex-row">
        <div class="block text-2xl text-white">{{ order.LOCATION_DATA.address || '-' }}</div>
      </div>

      <!-- Name -->
      <div class="flex flex-row">
        <div class="block text-xl text-white">{{ order.customername }}</div>
      </div>

      <div class="flex flex-row mt-2">
        <!-- Navigate -->
        <a :href="'https://www.google.com/maps/search/?api=1&query=' + order.LOCATION_DATA.address" target="_blank"
           class="inline-block cursor-pointer bg-blue-500 hover:bg-blue-600 text-white rounded-lg flex-1 text-center text-2xl py-2 mr-2"
           title="Navigeeri sihtkohta">
          <i class="fas fa-directions"></i>
        </a>

        <!-- Call -->
        <div v-if="order.contactphones" @click="contact = !contact"
             class="inline-block cursor-pointer bg-green-500 hover:bg-green-600 text-white rounded-lg flex-1 text-center text-2xl py-2 mr-2"
             title="Helista kliendile">
          <i class="fas fa-phone-alt"></i>
        </div>

        <!-- Load select -->
        <div @click="toggleSelected(order.documentno)"
             class="inline-block cursor-pointer bg-gray-600  text-white rounded-lg flex-1 text-center text-2xl py-2"
             title="Lisa või eemalda veo laadimisnimekirjast">
          <i v-if="!$store.state.selectedOrders.includes(order.documentno)"
             class="fas fa-check text-2xl"></i>
          <i v-if="$store.state.selectedOrders.includes(order.documentno)"
             class="fas fa-truck text-2xl"></i>
        </div>
      </div>

      <!-- Contact details -->
      <div class="text-gray-800 px-2 py-2 mt-4 rounded-lg bg-white overflow-hidden" v-if="contact">
        <ContactPhones :contacts="order.contactdetails" :phones="order.contactphones"/>
      </div>

      <!-- Add event -->
      <div class="flex flex-row mt-4 mb-2">
        <div class="w-full">
          <router-link :to="'/events/' + order.documentno"
                       class="block w-full bg-white py-2 px-4 rounded-lg font-thin text-center text-3xl text-blue-600"
                       @click="event.add = !event.add">
            Lisa sündmus
          </router-link>
        </div>
      </div>

      <!-- Delivery instructions -->
      <div v-if="order.text2 || moment(new Date()).unix() < moment(order.deadline).unix() && order.timed === true" class="bg-yellow-200 text-gray-800 px-4 py-4 mt-4 rounded-lg">
        <div v-if="moment(new Date()).unix() < moment(order.deadline).unix() && order.timed === true">
          <b>Tarneaeg: </b> {{ moment(order.deadline).format('DD. MMMM HH:mm') }}
        </div>
        {{ order.text2 }}
      </div>

      <!-- Delivery time passed -->
      <div v-if="moment(new Date()).unix() > moment(order.deadline).unix()"
           class="bg-red-600 text-white px-4 py-4 mt-4 rounded-lg md:py-2">
        <i class="fas fa-exclamation-triangle float-right mt-1"></i>
        <span class="font-semibold">Tarne on hilinenud!</span><br>Tarneaeg oli
        {{ moment(order.deadline).format('DD. MMMM') }}
        <span v-if="order.timed === true">{{ moment(order.deadline).format('HH:mm') }}</span>
      </div>

      <!-- Order rows header -->
      <div class="bg-white py-1 px-4 pr-8 mt-4 rounded-t-lg w-full">&nbsp;</div>

      <!-- Order rows -->
      <table class="table-auto w-full bg-white">
        <thead>
        <tr>
          <th class="px-2 pb-2 font-thin text-left">&nbsp;</th>
          <th class="px-2 pb-2 font-thin">Kogus</th>
          <th class="px-2 pb-2 font-thin text-center">Hind tk</th>
          <th class="px-2 pb-2 font-thin">Summa</th>
          <th class="px-2 pb-2 font-thin">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr class="border-t" v-for="row in order.ROWS" v-bind:key="row.id">
          <td class="px-2 py-2">
            <small>{{ row.searchcode }}</small><br>
            {{ row.text }}
          </td>
          <td class="px-2 py-2 lowercase text-center">
            <small v-if="row.service !== '+' && row.searchcode !== 'KM'">
              {{ row.quantity }} {{ row.measurementunit_id }}
            </small>
          </td>
          <td class="px-2 py-2 text-center">
            <small v-if="row.service !== '+' && row.searchcode !== 'KM'">
              {{ row.price }}
            </small>
          </td>
          <td class="px-2 py-2 text-center">
            <small>
              {{ row.amount }}
            </small>
          </td>
          <td class="px-2 py-2 text-center">
                        <span v-if="row.service !== '+' && row.searchcode !== 'KM'" class="text-gray-400">
                            <router-link :to="'/modify/' + order.documentno + '/' + row.rowno" class="p-2"
                                         title="Muuda rida">
                                <i class="fas fa-edit"></i>
                            </router-link>
                        </span>
          </td>
        </tr>

        </tbody>
      </table>

      <!-- Order rows footer -->
      <div class="bg-white pb-2 pt-2 px-4 text-right rounded-b-lg w-full">
        &nbsp;
      </div>

      <!-- Order notes -->
      <div class="flex flex-row mt-4">
        <textarea class="bg-white py-6 px-4 rounded-lg w-full placeholder-gray-500" name="notes" v-model="input.notes" placeholder="Sisesta kliendi arvele tellimuse kohta märkus (valikuline)"></textarea>
      </div>

      <!-- Total amount due-->
      <div class="flex flex-row mt-16">
        <div class="block text-xl text-white">
          Tasumisele kuulub
        </div>
      </div>
      <div class="flex flex-row mb-4">
        <div class="block text-3xl text-white">
          {{ order.amounttopay }} EUR
        </div>
      </div>

      <!-- Payment type -->
      <div class="flex flex-row mt-2">
        <select class="block w-full py-6 px-4 rounded-lg bg-white" name="payment" v-model="input.payment">
          <option value="" disabled selected>Vali makseviis</option>
          <option v-for="mode in paymentmodes" v-bind:key="mode.id" :value=" mode.id ">{{ mode.name }}</option>
        </select>
      </div>

      <!-- Receipt checkbox -->
      <div class="flex flex-row mt-2">
        <label class="block text-white">
          <input class="mr-2" @click="input.receipt = !input.receipt" type="checkbox">
          <span>Kviitung e-posti teel</span>
        </label>
      </div>

      <!-- Receipt email selection -->
      <div v-if="input.receipt">
        <!-- List of customer emails -->
        <div v-for="contact in orderBy(order.contactdetails, 'main', -1)" :key="contact.id" class="block mb-2">
          <div v-if="contact.email !== null">
            <input type="radio" name="email" :id="contact.id" v-model="input.contactId" :value="contact.id">
            <label :for="contact.id" class="text-2xl ml-2 text-white font-thin">{{ contact.email }} <span v-if="contact.main">(Asukoha kontakt)</span></label>
          </div>
        </div>
        <!-- Add new email -->
        <div v-if="!input.addEmail">
          <button @click="input.addEmail = !input.addEmail" class="block w-full bg-white py-2 px-2 rounded-lg font-thin text-center text-xl text-blue-600">Lisa uus e-mail</button>
        </div>
        <div v-else class="flex flex-row mt-4">
          <div class="w-3/5 pr-1">
            <input type="email" v-model="input.newEmail" maxlength="65" class="w-full py-4 px-4 mt-1 rounded-lg bg-white" placeholder="Sisesta kliendi uus e-posti aadress">
          </div>
          <div class="w-2/5 pl-1">
            <button @click="addEmail" class="w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-xl text-blue-600">Salvesta</button>
          </div>
        </div>
      </div>

      <!-- Customer signature -->
      <!--
        <div class="flex flex-row mt-4">
            <div class="block text-3xl text-white">Kohaletoimetamise kinnitus</div>
        </div>

        <div class="flex flex-row mt-2">
            <small class="text-white">
                Kliendi allkiri
            </small>
        </div>
        <div class="flex flex-row mt-2">
            <VueSignaturePad class="bg-white" width="100%" height="200px" ref="signaturePad" />
        </div>
      -->

      <!-- Confirm or cancel -->
      <div class="flex flex-row mt-16 mb-8">
        <div class="w-2/5 pr-1">
          <router-link to="/orders"
                       class="block w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-3xl text-red-600">
            Katkesta
          </router-link>
        </div>

        <div class="w-3/5 pl-1">
          <button @click="confirm()"
                  class="block w-full bg-white py-4 px-4 rounded-lg font-thin text-center text-3xl text-green-600">
            Kinnita tarne
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
import ContactPhones from '../components/ContactPhones'

moment.locale('et')

export default {
  mixins: [Vue2Filters.mixin],
  components: { ContactPhones },
  data () {
    return {
      order: null,
      paymentmodes: [],
      error: false,
      contact: false,
      expandedContacts: null,
      input: {
        payment: '',
        notes: '',
        receipt: false,
        contactId: '',
        newEmail: '',
        addEmail: false
      }
    }
  },
  mounted () {
    this.openOrder()
  },
  methods: {
    // Get order data from local memory and refresh it from Noom
    openOrder () {
      const _this = this
      _this.$store.commit('loading', true)
      // Populate order data from local memory
      _this.order = _this.$store.state.orders.find(item => item.documentno === _this.$route.params.order_id)
      _this.getPaymodes()

      // Get fresh order data from API
      axios.get(_this.$store.state.config.api + '?act=query&qid=API_ORDERS&param={"documentno":"' + _this.$route.params.order_id + '"}&accesstoken=' + _this.$store.state.user.data.accesstoken)
        .then(function (response) {
          console.log('Order fresh data:')
          console.log(response.data.data[0])
          if (response.data.status !== 0) {
            if (response.data.data[0].docstate_id === 'KTT') {
              console.log('Order already closed, removing from local memory!')
              _this.$store.commit('hideOrder', _this.order)
              _this.$router.push('/orders')
            } else {
              _this.$store.commit('updateOrder', response.data.data[0])
              _this.order = response.data.data[0]
              _this.order.contactdetails.forEach(function (c) {
                if (c.main === true) {
                  _this.input.contactId = c.id
                }
              })
              _this.$store.commit('loading', false)
            }
          } else {
            _this.$toast.error('Andmete uuendamine ebaõnnestus...')
            _this.$store.commit('loading', false)
          }
        })
        .catch(function (error) {
          console.log('API_ORDERS error', error)
        })
    },
    // Load available payment methods for this order
    getPaymodes () {
      const _this = this
      axios.get(_this.$store.state.config.api + '?act=ODOC_GETPAYMODES&docuid=' + _this.order.unique_id + '&accesstoken=' + _this.$store.state.user.data.accesstoken)
        .then(function (response) {
          console.log('getpaymodes:', response)
          if (response.data.data !== null) {
            _this.paymentmodes = response.data.data.paymentmodes
          } else {
            console.log('GETPAYMODES_ERROR = null')
          }
        })
        .catch(function (error) {
          console.log('GETPAYMODES error', error)
        })
    },
    // Add or remove order from loading plan
    toggleSelected (order) {
      this.$store.commit('toggleSelectedOrders', order)
      this.$toast.info('Veo laadimisplaani uuendati!')
    },
    // Add new contact email
    addEmail () {
      const _this = this
      if (_this.input.newEmail !== '') {
        axios.post(_this.$store.state.config.api + '?act=query&qid=API_CUSTCONTACT&qop=insert&attrib=OPINFO&par.customer_id=' + _this.order.customer_id + '&accesstoken=' + _this.$store.state.config.api, {
          data: [
            {
              customer_id: _this.order.customer_id,
              email: _this.input.newEmail
            }
          ]
        })
          .then(function (response) {
            console.log('new_id', response.data.data[0]._opinfo_.new_id, 'new email', response.data.data[0].email)
            console.log(response)
            _this.openOrder()
            _this.input.newEmail = ''
            _this.input.addEmail = false
          })
          .catch(function (error) {
            console.log('API_CUSTCONTACT error', error)
            _this.$toast.error('Palun sisesta kliendi uus e-maili aadress', { timeout: 5000 })
          })
      } else {
        this.$toast.warning('Palun sisesta kliendi uus e-maili aadress', { timeout: 5000 })
      }
    },
    // Confirm delivery
    confirm () {
      // e.preventDefault()
      // If no paymentmode selected
      if (!this.input.payment) {
        this.$toast.warning('Palun vali kasutatav makseviis!', { timeout: 5000 })
      } else {
        // If receipt is checked but no email is filled
        if (this.input.receipt && this.input.contactId === '') {
          this.$toast.warning('Palun vali kviitungi saatmiseks kliendi e-posti aadress!', { timeout: 5000 })
        } else {
          const _this = this
          _this.$analytics.logEvent('order_delivery_start')
          // Ask for confirmation
          this.$swal.fire({
            title: 'Kinnita tarne',
            text: 'Tellimus on kliendile kohaletoimetatud ning tellimuse eest on tasutud',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#e55a03',
            cancelButtonColor: '#ccc',
            confirmButtonText: 'Kinnitan',
            cancelButtonText: 'Tagasi'
          }).then((result) => {
            if (result.isConfirmed) {
              _this.$store.commit('loading', true)

              // Check if document is still in not-delivered state in DB
              axios.get(_this.$store.state.config.api + '?act=query&qid=API_ORDERS&param={"documentno":"' + _this.$route.params.order_id + '"}&accesstoken=' + _this.$store.state.user.data.accesstoken)
                .then(function (response) {
                  if (response.data.data[0].docstate_id === '') {
                    // Create linked document (S1->S5)
                    // const deliveryDocType = (_this.order.ODOC_GETDOC.document.header.additional16 === 'S3') ? 'S3' : 'S5'
                    const deliveryDocType = 'S5'
                    axios.get(_this.$store.state.config.api + '?act=ODOC_NEW&doctype=LV&DOCST=' + deliveryDocType + '&related.docuid=' + _this.order.unique_id + '&accesstoken=' + _this.$store.state.user.data.accesstoken)
                      .then(function (newResponse) {
                        console.log('newResponse', newResponse)

                        // Add payment to delivery document
                        const deliveryDocumentId = newResponse.data.data.docuid
                        axios.get(_this.$store.state.config.api + '?act=ODOC_SETPAYMENT&docuid=' + deliveryDocumentId + '&paymentmode=' + _this.input.payment + '&paymenttype=P&currency=EUR&amount=' + _this.order.amounttopay + '&accesstoken=' + _this.$store.state.user.data.accesstoken)
                          .then(function (paymentResponse) {
                            console.log('paymentResponse', paymentResponse)

                            // Close delivery document in database
                            axios.get(_this.$store.state.config.api + '?act=ODOC_COMPLETE&docuid=' + deliveryDocumentId + '&accesstoken=' + _this.$store.state.user.data.accesstoken)
                              .then(function (deliveryCompleteResponse) {
                                console.log('deliveryCompleteResponse', deliveryCompleteResponse)

                                // Add event on order document
                                const deliveryPaymentType = (_this.input.payment === 'B') ? 'BANKPAY' : 'CASHPAY'
                                axios.get(_this.$store.state.config.api + '?act=ODOC_ADDEVENT&docuid=' + deliveryDocumentId + '&de_act=' + deliveryPaymentType + '&de_comment=' + _this.order.amounttopay + '€&accesstoken=' + _this.$store.state.user.data.accesstoken)
                                  .then(function (addEventResponse) {
                                    console.log('addEventResponse', addEventResponse)
                                    if (addEventResponse.data.status !== 1) {
                                      _this.$toast.error('Tarne kinnitamine ebaõnnestus (ODOC_ADDEVENT)...', { timeout: 5000 })
                                    } else {
                                      // Send customer a receipt by email if this was requested
                                      if (_this.input.receipt && _this.input.contactId !== '') {
                                        axios.get(_this.$store.state.config.api + '?act=receipt&order=' + _this.order.unique_id + '&delivery=' + deliveryDocumentId + '&contact=' + _this.input.contactId)
                                          .then(function (response) {
                                            console.log('Send receipt response', response)
                                          })
                                          .catch(function (error) {
                                            console.log('Send receipt error', error)
                                            _this.$toast.error('Kviitungi saatmine ebaõnnestus...', { timeout: 5000 })
                                          })
                                      }

                                      // Show delivery confirmation popup
                                      let timerInterval
                                      _this.$swal.fire({
                                        title: 'Tehtud!',
                                        icon: 'success',
                                        timer: 2000,
                                        timerProgressBar: true,
                                        didOpen: () => { timerInterval = setInterval(() => {}, 100) },
                                        willClose: () => { clearInterval(timerInterval) }
                                      }).then((result) => {
                                        _this.$analytics.logEvent('order_delivery_complete')
                                        _this.$store.commit('hideOrder', _this.order)
                                        _this.$router.push('/orders')
                                      })
                                    }
                                  })
                                  .catch(function (error) {
                                    console.log('ODOC_ADDEVENT error', error)
                                    this.$toast.error('Tarne kinnitamine ebaõnnestus (ODOC_ADDEVENT)...', { timeout: 5000 })
                                  })
                              })
                              .catch(function (error) {
                                console.log('ODOC_COMPLETE error', error)
                                this.$toast.error('Tarne kinnitamine ebaõnnestus (ODOC_COMPLETE)...', { timeout: 5000 })
                              })
                          })
                          .catch(function (error) {
                            console.log('ODOC_SETPAYMENT error', error)
                            this.$toast.error('Tarne kinnitamine ebaõnnestus (ODOC_SETPAYMENT)...', { timeout: 5000 })
                          })
                      })
                      .catch(function (error) {
                        console.log('ODOC_NEW error', error)
                        this.$toast.error('Tarne kinnitamine ebaõnnestus (ODOC_NEW)...', { timeout: 5000 })
                      })
                  } else {
                    console.log('DOCUMENT WAS ALREADY CLOSED!')
                    _this.$toast.error('Tellimus oli juba lõpetatud!', { timeout: 5000 })
                    _this.$store.commit('hideOrder', _this.order)
                    _this.$router.push('/orders')
                  }
                })
            }
          })
        }
      }
    }
  }
}
</script>
